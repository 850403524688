
























































import { Component, Vue, Watch } from "vue-property-decorator";

@Component
export default class DayBook extends Vue {
  private mode = "overview";
  private name = "Voucher";
  private modulename = "voucher";
  private selectedItem: any = null;
  private tempItem: any = null;
  private data: any = { name: "", plural: "", singular: "", description: "" };
  private selections: Array<any> = [];
  private selectAll = false;
  private query: any = { dateFrom: this.today, dateTo: this.today, searchText: "", machineId: [] };
  private vouchers: Array<any> = [];

  @Watch('mode')
  modeChanged(value: string) {
    this.clear();
  }

  @Watch("selectAll")
  selectAllChanged(value: boolean) {
    if (value) {
      const count = this.items.length;
      this.selections = [];
      for (let i = 0; i < count; i++) {
        this.selections.push(this.items[i]._id);
      }
    } else {
      this.selections = []
    }
  }

  removeSelections () {
    const indices: Array<any> = this.selections.map(i => i);
    for (let i = 0; i < indices.length; i++) {
      this.remove(indices[i]);
    }
    this.selections = [];
    this.selectAll = false;
  }

  alter (voucherId: any) {
    this.$router.replace(`/dashboard/transactions/manufacturing/${voucherId}/alter`)
  }

  remove (voucherId: any) {
    this.$store.dispatch(`${this.modulename}/remove`,{ id: voucherId, callback: this.onRemoved });
  }

  onSaved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = this.mode === "create" ? `${this.name} Successfully Created!` : `${this.name} Successfully Updated!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  onRemoved (e: any, d: any) {
    let msg = ""
    if (d) {
      this.clear();
      msg  = `${this.name} Successfully Removed!`;
      this.$store.commit("SHOW_SUCCESS", msg)
    } else {
      msg = e.message;
      this.$store.commit("SHOW_ERROR", msg)
    }
  }

  clear () {
    this.data = { name: "", plural: "", singular: "", description: "" }
    this.selectedItem = null;
    this.tempItem = null;
  }

  selectObject (sitem: any) {
    this.selectedItem = sitem;
    if (this.selectedItem) {
      const item = this.items.filter((i: any) => i._id && i._id.toString() === this.selectedItem.toString())[0];
      if (item) {
        this.data = JSON.parse(JSON.stringify(item));
      }
    }
  }

  get items () {
    const vchs = this.vouchers;
    const stkitems = this.stockitems
    const units = this.units
    const sitems: any = {}
    const items = []

    const entries = vchs.reduce((c: any, r: any) => {
      return c.concat(r.inventoryEntriesIn || [])
    }, [])

    let indexCount = 0;
    for (let i = 0; i < entries.length; i++) {
      const itemId = entries[i].itemId.toString();
      const qty = entries[i].quantity;
      const waste = entries[i].waste || 0;
      
      if (!(sitems[itemId] || sitems[itemId] === 0)) {
        const stk = stkitems.filter((s: any) => s._id.toString() === itemId.toString())[0]
        if (stk) {
          const unit = units.filter((u: any) => stk.unit && u._id.toString() === stk.unit.toString())[0]
          items.push({ quantity: 0, waste: 0, name: stk.name , unit: unit ? unit.plural : "", _id: itemId, weight: stk.weight || 0 })
        } else {
          items.push({ quantity: 0, waste: 0, name: "", unit:  "", _id: itemId, weight: 0 })
        }
        sitems[itemId] = indexCount;
        indexCount += 1;
      }
      const ind: number = sitems[itemId];
      items[ind].quantity += Number(qty);
      items[ind].waste += Number(waste);

    }

    return items;
  }
  
  get machines () {
    const items = this.$store.state.machine.items;
    return items;
  }

  get stockitems () {
    const items = this.$store.state.stockitem.items;
    return items;
  }

  get units () {
    const items = this.$store.state.unit.items;
    return items;
  }

  loadItems () {
    const query: any = {}
    const dt = new Date(this.query.dateTo);
    const df = new Date(this.query.dateFrom)
    df.setUTCHours(0, 0 ,0, 0)
    dt.setUTCHours(23, 59, 59, 999);
    query.date = { $lte: dt, $gte: df }
    if (this.query.searchText) {
      query.$text = {$search: this.query.searchText}
    }

    if (this.query.machineId.length > 0) {
      query.machineId = {$in: this.query.machineId}
    }
    this.$store.dispatch(`${this.modulename}/find`, {query, callback: this.onLoaded});
  }

  onLoaded (e: any, d: any) {
    if (d) this.vouchers = d;
    else this.vouchers = [];
    this.selectAll = false;
    this.selections = [];
  }

  get canSave () {
    return this.data.name && this.data.plural && this.data.singular
  }

  get canRemove () {
    return this.selectedItem
  }

  mounted () {
    this.$store.commit('FOOTER_VISIBLE', false);
    this.$store.dispatch(`machine/load`)
    this.$store.dispatch(`unit/load`)
    this.$store.dispatch(`stockitem/load`)
  }

  formatDate(dt: Date) {
    const y = dt.getFullYear();
    const m = dt.getMonth() + 1;
    const d = dt.getDate();
    const ms = m < 10 ? `0${m}` : m.toString();
    const ds = d < 10 ? `0${d}` : d.toString();
    return `${y}-${ms}-${ds}`;
  }

  get today() {
    const dt = new Date(Date.now());
    return this.formatDate(dt);
  }

}
